import React from 'react'
import { graphql } from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
import SEO from '../components/SEO'
import Logo from '../components/Logo'
import Header from '../components/Header'
import ModalContainer from '../components/ModalContainer'
import Footer from '../components/Footer'
import Hero from '../containers/Hero'

class EventsPage extends React.Component {
  componentDidMount() {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const heroContainer = document.getElementById('hero');
      const topContainer = document.getElementById('top');

      window.addEventListener('scroll', () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const maxScroll = documentHeight - windowHeight;
        const scrollPercentage = Math.min(scrollPosition / maxScroll, 1);

        heroContainer.style.top = `-${(scrollPercentage * 2000)}px`
        topContainer.style.height = `${(scrollPercentage * 4000)}px`
        topContainer.style.top = `-${(scrollPercentage * 1500)}px`
      });
    }
  }

  render() {
    const {
      data: {
        banner,
          mobilebanner,
          footerbanner,
          logo,
          logohover,
          vcrop
      },
      pageContext: {
        locale,
          slug,
          content,
          alternates
      },
      location
    } = this.props;

    const banners = {
      desktop: banner.edges[0].node.original.src,
      mobile: mobilebanner.edges[0].node.original.src,
      footerBanner: footerbanner.edges[0].node.original.src,
    }
    

    return (
      <React.Fragment>
        <SEO
          pathname={location.pathname}
          locale={locale}
          banner={banners.desktop}
          slug={slug}
        />
        <Header
          slug={slug}
          locale={locale}
          location={location}
          alternates={alternates}
        />

        <Hero id="hero">
          <div id="david-jarre-mosaic-pages" style={{
            backgroundImage: `url(${banners.desktop})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionY: "0vh",
          }}>
          </div>
        </Hero>

        <div id="top" style={{
          backgroundImage: `url(${vcrop.publicURL})`,
          height: 0,
          width: "100vw",
          top: 0,
          zIndex: -1,
          position: "fixed",
          mixBlendMode: "screen",
        }}></div>

        <MainWrapper>

          <section className="content regular" style={{ paddingBottom: "3rem" }}>
            <div style={{ margin: "-4rem 0 4rem 0" }}>
              <ModalContainer
                triggerText={`Parlez nous de votre événement`}
                locale={locale}
                button={true}
              />
            </div>

            <div className="container magicFromHome">
              {
                content.map((item, index_) => {
                  return (
                    <React.Fragment key={`p-frag-${index_}`}>
                      {
                        item.map((i, index) => {
                          return (
                            <div className="row" key={`p-${index}`}>
                              <div className="col nf np">
                                <div dangerouslySetInnerHTML={{ __html: i }} />
                              </div>
                            </div>
                          )
                        })
                      }
                    </React.Fragment>
                  )
                })
              }

              <Logo
                logo={logo.edges[0].node.publicURL}
                logohover={logohover.edges[0].node.publicURL}
                className="page"
              />
            </div>
            <div style={{ margin: "-4rem 0 4rem 0" }}>
              <ModalContainer
                triggerText={`Parlez nous de votre événement`}
                locale={locale}
                button={true}
              />
            </div>

          </section>

        </MainWrapper>
        <Footer />
      </React.Fragment>
    )
  }
}

export default EventsPage
export const pageQuery = graphql`
  query Events {
    banner: allImageSharp(filter: {original: {src: {regex: "/cards/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    mobilebanner: allImageSharp(filter: {original: {src: {regex: "/cards/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    footerbanner: allImageSharp(filter: {original: {src: {regex: "/mfh-footer-banner-full/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    logo: allFile(filter: {relativePath: {eq: "david-jarre.com.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    logohover: allFile(filter: {relativePath: {eq: "vasarely.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    vcrop: file(base: {eq: "Varaselli-crop-dark.jpg"}) {
      publicURL
    }
  }
`